@function format-length($length)
  // @return $length * 1rem
  @return $length * 10px

@function calculate-position($v1 , $v2)
  @return $v1 + $v2

@mixin all-centering-flex($direction: row)
  display: flex
  justify-content: center
  align-items: center
  flex-direction: $direction

@function percentageFloat($float)
  @return '#{($float * 100)}%'

