.wrapper
  margin: 2.5rem 0 0
  padding: 0 2.5rem
  display: flex
  flex-wrap: wrap
  flex-direction: column
  width: 100%

.cardsWrapper
  display: flex
  flex-wrap: wrap
  margin-top: 3rem
  margin-bottom: 4.8rem
  justify-content: space-between

.header
  display: flex
  justify-content: space-between
  align-items: center

  .titleWrapper
    display: flex
    flex-direction: column

    h1
      font-size: 3.6rem
      font-weight: 900
      line-height: 1.33
      color: #222

    p
      font-size: 1.6rem
      color: #2d2d2d

.points
  position: relative
  width: 22.5rem
  height: 9.5rem
  background: linear-gradient(#febe19 0%, #feb817 46%, #fead12 100%)
  border-radius: 4px
  border: 1px solid #fcb514
  display: flex
  color: #fff
  align-items: flex-start
  padding: .7rem 1rem
  justify-content: space-between

  .img
    position: absolute
    bottom: 0
    left: 0
    width: 7.5rem
    height: 6rem

  p
    z-index: 1
    font-size: 1.8rem

  span
    z-index: 1
    display: flex
    align-self: flex-end
    flex-direction: column
    align-items: flex-end
    line-height: 2rem
    font-size: 2.7rem

    span
      font-size: 1.6rem
