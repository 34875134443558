@import ../../../../sass/colors

.footerWrapper
  width: 100%
  bottom: 0
  left: 0
  position: fixed
  display: flex
  align-items: center
  .navBar
    min-width: 10rem
    height: 9rem
  .footer
    background-image: linear-gradient(45deg, rgba(254,190,25,1) 0%, rgba(254,184,23,1) 46%, rgba(254,173,18,1) 100%)
    width: 100%
    padding: 1rem 5rem
    font-size: 2rem
    height: 9rem
    display: flex
    justify-content: space-between
    align-items: center
    .costWrapper
      display: flex
      align-items: center
      justify-content: space-between
      .totalCost
        display: flex
        flex-direction: column
        align-items: flex-start
        justify-content: center
        padding: 0 2rem
        *
          margin: 0
        .money
          color: white
          font-size: 2.8rem
          margin: -1.5rem 0 0 0
    .totalUsers
      display: flex
      justify-content: space-between
      align-items: center
      *
        margin: 0
        color: white



    .btn,.btnDisabled
      width: 23.7rem
      background-color: white
      border: none
      padding: 1.8rem 1rem
      font-size: 1.8rem
      align-self: center
      font-weight: 600
      border-radius: 4rem
      color: black
      transition: all .2s
      outline: none !important
    .btnDisabled
      background-color: #F3F3F3
      cursor: not-allowed
      color: black

    .btn:hover
      transform: translateY(-3px)
      box-shadow: $box-shadow-btn-hover
    .btn:active
      box-shadow: $box-shadow-btn-active
      transform: translateY(-1px)
