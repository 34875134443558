.wrapper
  height: 100vh
  width: 100%
  display: flex
  flex-direction: column

  .header
    padding: 1.1rem 13.5rem
    border: solid 1px #ebebeb
    background-color: #fff


    .backToEditor
      display: flex
      align-items: center
      justify-content: center
      border-radius: 26px
      border: solid 1px #38c172
      background-color: #fff
      font-size: 14px
      font-weight: 900
      padding: 1.5rem 3rem
      color: #38c172

      img
        height: 1.4rem
        width: 1.4em

  .exampleWrapper
    flex:  1
