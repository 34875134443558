@import '../../../../sass/colors'

.wrapper
  border-radius: 4px
  box-shadow: 1px 2px 10px 0 rgba(2, 10, 39, .1)
  padding: 0 2.4rem
  min-width: 49%
  display: flex
  align-items: center
  margin: 0
  margin-bottom: 2rem

  &:last-of-type
    margin-bottom: 0

  *
    margin: 0

  .indexWrapper
    border-radius: 50%
    width: 4rem
    height: 4rem
    color: #fdbb11
    display: flex
    align-items: center
    justify-content: center
    font-size: 2.6rem
    font-weight: 500

  .statIcon
    margin: 0 2.4rem

  .textWrapper
    display: flex
    flex-direction: column

    .title
      font-weight: 500

    .statNumber
      font-weight: 900



.smallCard
  &.wrapper
    background: #fff
    height: 8.2rem

  .indexWrapper
    background: #fff5dc

  .statIcon
    width: 6rem
    height: 6rem

  .textWrapper
    .title
      font-size: 1.6rem
      color: #2d2d2d
      letter-spacing: -.36px

    .statNumber
      color: #2d3039
      font-size: 2rem
      letter-spacing: -.5px

.bigCard

  &.wrapper
    background: linear-gradient(301deg, #fda711, #fdbb11)
    height: 14.5rem

  .indexWrapper
    background: #fff

  .statIcon
    width: 8rem
    height: 8rem

  .textWrapper
    .title
      font-size: 2.6rem
      color: #fff5dc
      letter-spacing: -.58px

    .statNumber
      color: #fff
      font-size: 4rem
      letter-spacing: -1px

