.wrapper
  background: #fff
  padding: 3rem 1rem
  padding-top: 0
  box-shadow: 1px 2px 10px 0 rgba(2, 10, 39, .1)
  border-radius: 10px
  margin: 3rem 0

  .chartHeader
    padding: 0 3rem
    border-bottom: 1px solid #dadfe2

  .yAxisLabel,
  .xAxisLabel
    font-size: 1.2rem
    font-weight: 900
    text-align: center

  .yAxisLabel
    fill: #939ca1

  .xAxisLabel
    fill: #9ba4a8
