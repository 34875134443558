@import '../../../../sass/colors'

.box
  box-shadow: 0 3px 4px 0 rgba(0,0,0,0.1)
  display: flex
  flex-direction: column
  align-items: flex-start
  padding: 1rem 5rem
  width: 100%

.wrapper
  max-height: 100%
  display: flex
  flex-direction: column
  align-items: center
  padding: 1rem 0
  width: 100%
  .giftsWrapper
    padding: 3rem 3rem 0
    width: 100%
    overflow: auto
    height: 46rem
    margin: 0
    .categorisedItems
      display: flex
      align-items: center
      justify-content: space-between
      flex-wrap: wrap
    .noItems
      width: 100%
      height: 100%
      font-size: 2rem
      display: flex
      justify-content: center
      align-items: center
    .header
      font-size: 1.8rem
      margin: 0 2rem
      text-transform: uppercase
      letter-spacing: 1px
      font-weight: bold
  .fieldWrapper
    margin: 1.5rem 0
    width: 100%
    .label
      font-size: 1.6rem
      font-weight: bold
    .search
      display: flex
      padding: 1.5rem
      height: 5rem
      justify-content: space-between
      border: 1px solid #E3E3E3
      border-radius: 8px
      align-items: center
      .logo
        height: 2rem
      .input
        outline: none
        border: none
        height: 4.8rem
        color: black
        font-size: 1.8rem
        flex: 1
      .input::placeholder
        color: #2d2d2d
      .input:disabled
        background-color: white
