@import ../../../../sass/colors

.pendingWrapper,
.scheduledWrapper,
.rejectedWrapper,
.completedWrapper,
.runningWrapper
  display: flex
  width: 100%
  justify-content: space-between
  align-items: center
  background-color: white
  padding: 2rem 3rem
  box-shadow: 1px 2px 10px 0 rgba(126, 91, 1, 0.1)
  margin: 0 0 1.2rem
  *
    margin: 0
    font-size: 1.8rem
    //@media only screen and (max-width: $bg-smallest-1)
      font-size: 1rem
  .title
    width: 13%
    //@media only screen and (max-width: $bg-smallest-2)
      font-size: 4px
    //@media only screen and (max-width: $bg-smallest-3)
      font-size: 3px
  .statusPending,.statusRejected,.statusPayment,.statusCompleted
    display: flex
    justify-content: flex-start
    align-items: center
    width: 20%
    color: $color-shopx-main-theme
    p
      margin: 0 1rem
      color: $color-black-text
      //@media only screen and (max-width: $bg-smallest-2)
        font-size: 4px
      //@media only screen and (max-width: $bg-smallest-3)
        font-size: 3px
  .statusRejected
    color: red
  .statusPayment
    color: $color-purple-status
  .statusCompleted
    color: $color-green-status

  .date
    display: flex
    width: 20%
    align-items: center
    p
      color: $color-black-text
      margin: 0 1rem
      //@media only screen and (max-width: $bg-smallest-2)
        font-size: 4px
      //@media only screen and (max-width: $bg-smallest-3)
        font-size: 3px
    .logo
      height: 2rem
  .user
    display: flex
    width: 25%
    align-items: center
    p
      color: $color-black-text
      margin: 0 1rem
      //@media only screen and (max-width: $bg-smallest-2)
        font-size: 4px
      //@media only screen and (max-width: $bg-smallest-3)
        font-size: 3px
    .logo
      height: 2rem
  .mask
    height: 4rem
    width: 4rem
    background-image: linear-gradient(45deg, rgba(254,190,25,1) 0%, rgba(254,184,23,1) 46%, rgba(254,173,18,1) 100%)
    padding: 1rem
    border-radius: 50%
    cursor: pointer
    transition: all .2s
    outline: none !important
  .delete
    height: 4rem
    width: 4rem
    cursor: pointer
    transition: all .2s
    outline: none !important

.delete:hover
  transform: translateY(-3px)
.delete:active
  transform: translateY(-1px)

.mask:hover
  transform: translateY(-3px)
  box-shadow: $box-shadow-btn-hover
.mask:active
  box-shadow: $box-shadow-btn-active
  transform: translateY(-1px)

.pendingWrapper,.scheduledWrapper
  border-left: 3px solid $color-shopx-main-theme

.rejectedWrapper
  border-left: 3px solid red

.completedWrapper
  border-left: 3px solid $color-green-status

.runningWrapper
  border-left: 3px solid $color-purple-status

.btn
  background-color: white
  border: .15rem solid $color-shopx-main-theme
  padding: .7rem 2rem
  text-align: center
  font-size: 1.6rem
  border-radius: 4rem
  margin: 0 .5rem
  color: $color-shopx-main-theme
  transition: all .2s
  outline: none !important
.btn:hover
  transform: translateY(-3px)
  box-shadow: $box-shadow-btn-hover
.btn:active
  box-shadow: $box-shadow-btn-active
  transform: translateY(-1px)

.btnsWrapper
  width: 10%
  display: flex
  align-items: center
  justify-content: space-between
