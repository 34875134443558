@import ../../../sass/colors

.wrapper
  display: flex
  flex-direction: column
  justify-content: flex-start
  width: 100%
  padding: 5rem
  .progressBarWrapper
    display: flex
    justify-content: center
    margin-bottom: 3rem
