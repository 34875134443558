@import '../../../sass/colors'


.wrapper
  display: flex
  flex-direction: column
  .formError
    font-weight: bold !important
    font-size: 1.6rem !important
    color: #FF0606 !important
.fullWidth
  border-radius: 10px !important
  min-width: 100.5% !important
.root
  border-radius: 10px !important
  margin: 0
  width: 99.5%
  padding: 0
  box-shadow: 1px 2px 10px 0 rgba(0, 0, 0, 0.1)
  border: solid 1px #ebebeb
  display: flex !important
  font-family: Avenir, 'Avenir LT Std', 'Cairo', 'Roboto', sans-serif !important
  header
    box-shadow: none !important

.tab
  outline: 0 !important
  padding: 0 !important
  background-color: white !important
  opacity: 1 !important
  display: flex !important
  justify-content: center !important
  align-items: center !important
  flex: 1 !important
  outline: 0 !important
  border: none !important
  color: #2d2d2d !important
  font-size: 1.8rem !important
  text-transform: none !important
  margin: 0 !important
  z-index: 5 !important
  min-width: 50% !important
.disabled
  background: #ebebeb !important
  cursor: not-allowed !important

.selectedTab
  flex: 1 !important
  fill: $color-shopx-main-theme !important
  text-transform: none !important
  background-color: white !important
  color: $color-shopx-main-theme !important
  outline: 0 !important
  border: none !important
  position: relative !important
  .incentiveWrapper
    background: #fdbb11 !important
    .textWrapper
      .title
        color: white !important
      .subTitle
        color: #fff5dc !important
    .dotWrapper
      border: 1px solid white !important
      background: #fdbb11 !important
      .dot
        background: white !important


.indicator
  display: none !important
  margin-left: 69px !important
  width: 3rem !important
  height: 3rem !important
  transform: rotate(180deg)
  background: #fdbb11 !important
  clip-path: polygon(50% 50%, 0% 100%, 100% 100%)


.incentiveWrapper
  display: flex
  align-items: center
  height: 13rem
  width: 100%
  position: relative
  transition: all .3s
  *
    margin: 0
  img
    width: 4.4rem
    height: 5rem
    margin: 0 2rem
  .dotWrapper
    position: absolute
    right: 1.5rem
    top: 1.5rem
    width: 2.6rem
    height: 2.6rem
    border-radius: 50%
    background: white
    display: flex
    align-items: center
    justify-content: center
    border: solid 1px #ebebeb
    .dot
      width: 1.5rem
      height: 1.5rem
      border-radius: 50%
      background: white
  .textWrapper
    display: flex
    flex-direction: column
    align-items: flex-start
    text-align: left
    .title
      font-size: 1.6rem
      font-weight: 900
      color: #2d2d2d !important
    .subTitle
      font-size: 1.5rem
      color: #2d2d2d !important

.incentiveWrapper:hover
  background: #f3f3f3
  .dotWrapper
    .dot
      background: #fee6a9
