@import ./sass/colors

.App
  background-color: #fafafa
  min-height: 100vh
  font-family: Avenir, 'Avenir LT Std','Cairo','Roboto', sans-serif
  font-weight: 400
.dashboardPreview
  background-color: #41126c
  color: white
  display: flex
  flex-direction: column
  justify-content: center
  align-content: center
  width: 100vw
  padding: 5px 0
  z-index: 999999999999
  font-weight: 700
  font-size: 12px
  >span
    align-self: center

.rtl
  direction: rtl

.languageBTN
  width: 10rem
  background-color: #ffc013
  border: 0
  border-radius: 3rem
  color: black
.mainSection
  display: flex
.secondarySection
  display: flex
  width: 100%
  position: relative
  flex-direction: column

.navBar
  min-width: 21.5rem
  min-height: 100vh
  padding: 5rem 0
