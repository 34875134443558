@import '../sass/functions'

.wrapper
  +all-centering-flex(column)

  height: 100%
  // max-height: 100%
  background-image: url('../assets/coins-pattern.png')
  width: 100%

  &.mockUp
    width: 100%
    border-radius: 30px
    box-sizing: border-box
    max-height: 550px
    overflow-y: scroll
    overflow-x: hidden 
    &::-webkit-scrollbar 
        width: 5px    

    &::-webkit-scrollbar-track 
        border-radius: 10px
        color: white

    &::-webkit-scrollbar-thumb 
        border-radius: 500px
        width: 1px
        background: #eee


  &.liveApp
    min-height: 100vh

  *
    color: inherit
    margin: 0
