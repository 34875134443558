@import ../../../sass/colors

.form
  padding: 4.5rem 2.5rem
  .header
    font-size: 2.4rem
    font-weight: 900
    letter-spacing: .64px
    color: #222
    margin: 6rem 0 0
  .para
    color: #2d2d2d
    font-size: 1.6rem
    margin: 0 0 1rem
  .title
    color: #222
    font-size: 3.6rem
    font-weight: 900
    line-height: 1.33
    margin: 0 0 5rem !important
