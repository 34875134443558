@import ../../../../sass/colors


.wrapper
  display: flex
  align-items: center
  min-width: 50%
  margin: 2rem 0


.cardWrapper:hover
  .btnWrapper
    opacity: 1
.cardWrapper
  min-width: 38rem
  width: 100%
  height: 9.5rem
  margin: 0 2rem
  display: flex
  justify-content: space-between
  align-items: center
  padding: 2rem
  border-radius: 5px
  box-shadow: 1px 2px 10px 0 rgba(0, 23, 59, 0.1)
  .btnWrapper
    display: flex
    opacity: 0
    .btn
      background: none
      margin: 0 1rem
      border: 0
      outline: none !important
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1)
      display: flex
      width: 4rem
      height: 4rem
      border-radius: 50%
      align-items: center
      justify-content: center
      transition: all .2s
      .img
        width: 1.7rem
        height: 1.7rem
    .btn:hover,.btn:active
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1)
  .itemImg
    border-radius: 50%
    width: 6rem
    height: 6rem
  .dataWrapper
    margin: 0 2rem
    display: flex
    flex: 1
    flex-direction: column
    align-items: flex-start
    .itemName
      font-size: 2rem
      font-weight: 900
      color: #2d2d2d
    .itemPrice
      display: flex !important
      align-items: center
      justify-content: space-between !important
      width: 100%
      color: #2d2d2d
      font-weight: 500
      font-size: 1.6rem
    *
      margin: 0

.blur
  opacity: .5
.active
  .cardWrapper
    background: #fdbb11
    *
      color: white !important
