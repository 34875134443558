.indexedStep
  color: white
  width: 2.5rem
  height: 2.5rem
  font-size: 1.2rem
  background-color: rgba(211, 211, 211, 0.8)
  border-radius: 50%
  display: flex
  justify-content: center
  align-items: center


.indexedStep.accomplished
  background-color: orange
