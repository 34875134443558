@import ../../../sass/colors

.wrapper
  display: flex
  flex-direction: column
  padding: 0 2.5rem
  flex: 1
  .title
    display: flex
    align-items: center
    justify-content: space-between
    color: #222
    font-size: 2.8rem
    font-weight: 900
    line-height: 1.71
    text-transform: uppercase
    .showAll
      width: 12rem
      height: 5rem
      border-radius: 3rem
      background: rgba(	253, 174, 17,.2)
      display: flex
      align-items: center
      justify-content: center
      font-size: 14px
      border: 0
      outline: 0
      font-weight: 900
      color: #fdbb11
  .filterWrapper
    display: flex
    justify-content: space-between
    align-items: flex-end
    padding: 1rem 0
    margin: 1rem 0
    border-bottom: 1px solid $color-border
  .operationsWrapper
    .header
      width: 100%
      display: flex
      align-items: center
      padding: 0 3rem
      height: 5.5rem
      border-radius: 5px
      box-shadow: 1px 2px 10px 0 rgba(2, 10, 39, 0.1), 0 3px 14px 0 rgba(0, 0, 0, 0.1)
      background: #feb716
      margin: 1.2rem 0
      justify-content: space-between
      .th1,.th2,.th3,.th4
        font-size: 1.4rem
        color: white
        font-weight: 900
        width: 13%
      .th2,.th3
        width: 20%
      .th4
        width: 25%
    .rejectedText
      display: flex
      height: 100%
      font-size: 3rem
      padding: 1.5rem 0
      align-items: center
      justify-content: center
.btn,.btnActive
  background-color: white
  min-width: 6rem
  border: 1px solid $color-grey-paragraph
  padding: .5rem 1rem
  font-size: 1.6rem
  border-radius: 5px
  margin: 0 .5rem
  color: $color-grey-paragraph
  transition: all .2s
  outline: none !important

.btn:hover
  border: 1px solid $color-shopx-main-theme
  color: $color-shopx-main-theme
.btnActive
  border: 1px solid $color-shopx-main-theme
  color: $color-shopx-main-theme
