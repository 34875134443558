@import ../../sass/colors

.header
  height: 7rem
  background-color: #fff
  border-bottom: 1px solid #ebebeb
  width: 100%
  display: flex
  padding: 0 3rem
  align-items: center
.crumbsWrapper
  display: flex
  align-items: center
  font-size: 1.6rem
  font-weight: 500
  .disabledCrumb
    color: #2d2d2d
    margin: 0
  .crumb
    color: #2d2d2d
    cursor: pointer
    &:last-of-type, &:only-of-type
      color: #2d2d2d !important
      font-weight: 900
      pointer-events: none
  .separator
    font-size: 1rem
    color: #ebebeb
    margin: 0 1rem
    &:last-of-type
      display: none
