@import ../../../sass/colors


.width
  width: 47rem
.wrapper
  display: flex
  flex-direction: column
  background: white
  width: 60%
  border: solid 1px #ebebeb
  border-radius: 10px
  padding: 2.5rem 3.5rem
  margin: 6rem 0 5rem
  .header
    color: #222
    font-size: 2.4rem
    font-weight: 900
    letter-spacing: .64px
  .para
    color: #2d2d2d
    font-size: 1.6rem
    margin: 0 0 3.5rem

