@import ../../../sass/colors


.wrapper
  margin-bottom: 5rem
  width: 100%
  h1
    font-size: 2.8rem
    font-weight: 600



.row
  display: flex
  width: 100%
  justify-content: space-between
  align-items: center
  flex-wrap: wrap
  margin-top: -2rem
  margin-bottom: 6rem
  padding: 0


