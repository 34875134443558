.wrapper
  display: flex
  margin-bottom: 7.6rem
  flex-wrap: wrap

.header
  display: flex
  justify-content: space-between

.rejectedText
  display: flex
  font-size: 3rem
  color: #f00
  text-align: center

.title
  font-size: 2.8rem
  font-weight: 900
  line-height: 1.71
  color: #222
  text-transform: uppercase
  margin-bottom: 1.6rem
  display: flex
  align-items: center
  justify-content: space-between

  .showAll
    width: 12rem
    height: 5rem
    border-radius: 3rem
    background: rgba(253, 174, 17, .2)
    display: flex
    align-items: center
    justify-content: center
    font-size: 14px
    border: 0
    outline: 0
    font-weight: 900
    color: #fdbb11

.para
  font-size: 1.6rem
  color: #2d2d2d
  margin: -1.8rem 0 4rem
