@import ../../sass/colors

.app
  padding: 5rem 6rem 5rem 5.5rem
  background-color: white

.logo
  height: 14.5rem
  width: 10.5rem

.section
  margin-top: 5rem
  width: 100%
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  align-items: center

.text
  display: flex
  flex-direction: column
  align-items: flex-start
  @media only screen and (max-width: $bg-largest-2)
    margin-bottom: 7rem

.text h1
  font-size: 5rem
  font-weight: 500
  margin: 0

.text p
  font-size: 2.8rem
  font-weight: 400
  margin: 2rem 0

.btn
  border: none
  width: 22rem
  height: 6.8rem
  font-size: 2rem
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer
  border-radius: 4rem
  color: white
  transition: all 0.2s
  outline: none !important
  background-image: linear-gradient(45deg, rgba(254, 190, 25, 1) 0%, rgba(254, 184, 23, 1) 46%, rgba(254, 173, 18, 1) 100%)

.btn:hover
  transform: translateY(-3px)
  box-shadow: 0 3px 6px 0 rgba(0, 23, 59, 0.1)

.btn:active
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2)
  transform: translateY(-1px)

.btn img
  margin: 0 1rem 0 1.5rem
  transform: scaleX(-1)
  height: 2rem

/*Video styling*/
.video-wrapper
  position: relative

.background
  width: 50rem
  height: 45rem

.video-img
  //object-fit: contain
  object-position: center
  width: 55rem
  height: 35rem
  position: absolute
  top: 5rem
  left: -4rem
  filter: drop-shadow(0 12px 24px rgba(0, 0, 0, .2))
  border-radius: 1rem

.video-play-button
  position: absolute
  z-index: 10
  top: 50%
  left: 47%
  transform: translateX(-50%) translateY(-50%)
  box-sizing: content-box
  display: block
  width: 32px
  height: 44px
  background: #fdbb11
  border-radius: 50%
  padding: 18px 20px 18px 28px

.video-play-button:before
  content: ''
  position: absolute
  z-index: 0
  left: 50%
  top: 50%
  transform: translateX(-50%) translateY(-50%)
  display: block
  width: 80px
  height: 80px
  background: #fdc430
  border-radius: 50%
  animation: pulse-border 1500ms ease-out infinite

.video-play-button:after
  content: ''
  position: absolute
  z-index: 1
  left: 50%
  top: 50%
  transform: translateX(-50%) translateY(-50%)
  display: block
  width: 80px
  height: 80px
  background: #fdbb11
  border-radius: 50%
  transition: all 200ms

.video-play-button:hover:after
  background-color: darken(#fdbb11, 7%)

.video-play-button img
  position: relative
  z-index: 3
  max-width: 100%
  width: auto
  height: auto

.video-play-button span
  display: block
  position: relative
  z-index: 3
  width: 0
  height: 0
  border-left: 32px solid #fff
  border-top: 22px solid transparent
  border-bottom: 22px solid transparent

@keyframes pulse-border
  0%
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1)
    opacity: 1

  100%
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5)
    opacity: 0

.videoOverlay
  position: fixed
  z-index: -1
  top: 0
  bottom: 0
  left: 0
  right: 0
  background: rgba(0, 0, 0, 0.8)
  opacity: 0
  transition: all ease 500ms

.videoOverlay.open
  position: fixed
  z-index: 1000
  opacity: 1

.videoOverlayClose
  position: absolute
  z-index: 1000
  top: 15px
  right: 20px
  font-size: 36px
  line-height: 1
  font-weight: 400
  color: #fff
  text-decoration: none
  cursor: pointer
  transition: all 200ms

.videoOverlayClose:hover
  color: #fdbb11

.videoOverlay iframe
  position: absolute
  top: 50%
  left: 50%
  transform: translateX(-50%) translateY(-50%)
  /* width: 90% */
  /* height: auto */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.75)

/*---------------------Why section------------------------*/
.why-section
  margin-top: 20rem
  width: 100%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  @media only screen and (max-width: $bg-largest-2)
    margin-top: 10rem

.line
  background-color: #febe19
  width: 6rem
  height: 0.2rem
  border-radius: 4rem

.why-section h2
  font-size: 3.5rem
  margin: 2rem 0 5rem 0

.cards-wrapper
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  flex-wrap: wrap
  margin-bottom: 10rem

.card
  width: 36rem
  height: 23.5rem
  padding: 4rem 3rem
  border-radius: 5px
  display: flex
  margin: 2.2rem
  flex-direction: column
  justify-content: space-between
  background: #fff
  box-shadow: 0px 12px 24px rgba(0, 23, 59, 0.1)

.card-img
  height: 5.5rem
  width: 5.5rem

.card-title
  font-size: 2.2rem

.card-text
  margin-top: 1rem
  font-size: 1.8rem
  color: #898989

