@import '../../../../sass/colors'

.width
  width: 40rem
.wrapper
  display: flex
  flex-direction: column
  background: white
  width: 100%
  border: solid 1px #ebebeb
  border-radius: 0 0 10px 10px
  padding: 4.5rem 2rem 0
  margin: -2rem 0 0
  .cardsWrapper
    display: flex
    justify-content: space-between
    padding: 0 0 2rem
  .separator
    height: 1px
    width: 100%
    background: #ebebeb
    margin: 3rem 0 1rem
  .subH
    font-size: 1.6rem
    font-weight: 500
    color: #2d2d2d
  .error
    color: red
    font-size: 1.6rem
    margin: auto
    padding: 3rem 0 5rem

