@import ../../../sass/colors

.wrapper
  display: flex
  flex: 1
  flex-direction: column
  align-items: center
  justify-content: center
  .card
    border-radius: 10px
    border: solid 1px #fdbb11
    background: white
    width: 77rem
    height: 31rem
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-end
    padding: 0 0 3.3rem
    position: relative
    .img
      width: 13rem
      height: 13rem
      position: absolute
      left: 32rem
      top: -6.5rem
    .header
      font-size: 2.6rem
      letter-spacing: .69px
      color: #222
      font-weight: 900
    .para
      text-align: center
      font-size: 1.8rem
      color: #2d2d2d
      margin: 1rem 0 1.6rem
    .clipWrapper
      display: flex
      flex-direction: column
      width: 66.5rem
      .label
        font-size: 1.6rem
        font-weight: 900
        margin: 0 0 1rem
        color: #2d2d2d
      .input
        border-radius: 5px
        width: 100%
        display: flex
        height: 5.5rem
        .inputWrapper
          width: 70%
          position: relative
          .url
            white-space: nowrap
            border-radius: 5px 0 0 5px
            outline: 0
            padding: 0 2rem
            height: 100%
            width: 100%
            box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1)
            border: solid 1px #e3e3e3
            background-color: #ebebeb
            display: flex
            text-wrap: none
            flex-wrap: nowrap !important
            overflow: hidden !important
            align-items: center
            font-size: 1.6rem
            font-weight: 500
            color: #2d2d2d
          .shadow
            position: absolute
            right: 0
            top: 0
            background-image: linear-gradient(to left, #ebebeb, rgba(235, 235, 235, 0.37))
            width: 6rem
            height: 100%
        .clip
          border-radius: 0 5px 5px 0
          width: 30%
          display: flex
          align-items: center
          justify-content: center
          background-color: #fdbb11
          cursor: pointer
          .icon
            width: 1.6rem
            height: 1.6rem
            margin: 0 .4rem
          .text
            margin: 0 .4rem
            font-size: 1.4rem
            font-weight: 900
            color: white

  .btn
    outline: none
    font-size: 1.6rem
    width: 35rem
    height: 6.4rem
    font-weight: 900
    color: #481376
    border-radius: 5px
    border: solid 1px #481376
    background-color: #ffffff
    margin: 4rem 0 0
