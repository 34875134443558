* {
  margin: 0;
  padding: 0;
}
* button {
  cursor: pointer;
}

*,
*::after,
*::before {
  box-sizing: inherit;
  cursor: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}
@media only screen and (max-width: 85.625em) {
  html {
    font-size: 58%;
  }
}
@media only screen and (max-width: 80em) {
  html {
    font-size: 52%;
  }
}
@media only screen and (max-width: 68.75em) {
  html {
    font-size: 45%;
  }
}
@media only screen and (max-width: 56.25em) {
  html {
    font-size: 40%;
  }
}
@media only screen and (max-width: 46.875em) {
  html {
    font-size: 30%;
  }
}
@media only screen and (max-width: 37.5em) {
  html {
    font-size: 25%;
  }
}
@media only screen and (max-width: 25em) {
  html {
    font-size: 15%;
  }
}