@import ../../../sass/colors


.wrapper
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  margin: 4rem 0 2rem 0
  .title
    font-size: 2.8rem
  .paragraph
    margin: 1rem 0
    font-size: 1.8rem
  .logo
    margin: 4rem 0 5rem 0
    width: 7.7rem
    height: 7.7rem
  .confirmBtn,.cancelBtn
    background-image: linear-gradient(45deg, rgba(254,190,25,1) 0%, rgba(254,184,23,1) 46%, rgba(254,173,18,1) 100%)
    border: none
    width: 12rem
    margin: 0 1rem
    height: 4.5rem
    font-size: 1.6rem
    outline: 0
    padding: 0
    cursor: pointer
    border-radius: 4rem
    color: white
    transition: all .2s
    outline: none !important
  .cancelBtn
    color: #989A97
    background: #E3E3E3 none
  .confirmBtn:hover,.cancelBtn:hover
    transform: translateY(-3px)
    box-shadow: $box-shadow-btn-hover
  .confirmBtn:active,.cancelBtn:active
    box-shadow: $box-shadow-btn-active
    transform: translateY(-1px)
