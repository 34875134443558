@import '../SocialMediaConversion/styles.module'

.citiesTable
  margin-top: 2rem
  .rejectedText
    color: red
    font-size: 2rem
    padding: 3rem 0
  .noStats
    color: black
    font-size: 2rem
    padding: 3rem 0

  .header,
  .row
    display: flex
    justify-content: space-between

  .header
    font-size: 1.4rem
    font-weight: 900
    color: #2d2d2d
    text-transform: uppercase
    margin-bottom: 1rem

  .row
    font-size: 1.6rem
    font-weight: 500
    color: #2d2d2d
    margin-bottom: 1.6rem

    &:last-of-type
      margin-bottom: 0

    .value
      font-weight: 900
